.temp-class {
    display: flex;
    width: 100%;
    margin: 10px 10px;
    padding: 10px 10px;
}

.patient-information-bar {
    display: flex;
    align-items: center;
    width: 100%;

    background: $color-white;
    box-shadow: 0px 2px 15px rgba(89, 123, 142, 0.15);
    border-radius: 5px;
    padding: rem(17px) rem(36px) rem(25px) rem(36px);

    &__full-name-wrapper {
        border-right: 1px solid rgba($color-gray-light-2, 1);
        min-width: rem(120px);
    }

    &__full-name {
        display: flex;
        flex-direction: column;
        @include primaryFont;
        font-weight: 600;
        font-size: rem(20px);
        line-height: rem(24px);
        color: $color-orange-1;
        padding-right: rem(45px);
    }

    &__info-icons-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__text-font {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(14px);
        color: $color-blue-dark-1;
        opacity: 0.6;
    }

    &__first-name {
        display: flex;
    }

    &__last-name {
        display: flex;
    }

    &__general-info {
        display: flex;
        padding: 0  0 0 rem(45px);
    }

    &__status {
        display: flex;
        flex-direction: column;
        height: rem(47px);
        align-items: center;
        justify-content: space-between;
    }

    &__status-icon-wrapper {
        padding-top: 1px;
    }

    &__status-text {}

    &__room {
        display: flex;
        flex-direction: column;
        padding: 0 0 0 rem(45px);
        height: rem(47px);
        align-items: center;
        justify-content: space-between;
    }

    &__room-number {
        @include primaryFont;
        font-weight: 600;
        font-size: rem(16px);
        color: $color-blue-dark-1;
    }


    &__passkey-wrapper {
        display: flex;
        flex-direction: column;
        height: rem(47px);
        align-items: center;
        justify-content: space-between;
        padding: 0 rem(45px);
    }

    &__passkey {
        @include primaryFont;
        font-weight: 600;
        font-size: rem(16px);
        color: $color-blue-dark-1;
    }

    &__room-text {}

    &__device-number-wrapper {
        display: flex;
        flex-direction: column;
        height: rem(47px);
        justify-content: space-between;
    }

    &__device-number {
        @include primaryFont;
        font-weight: 600;
        font-size: rem(16px);
        color: $color-blue-dark-1;
    }

    &__device-number-text {}

    &__icons {
        display: flex;
        align-items: center;
    }

    &__not-last-icon {
        padding: 0 1.2rem 0 0;
        display: flex;
        align-items: center;
    }
}
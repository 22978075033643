.custom-scheduler {

    display: flex;
    flex-direction: column;
    max-width: rem(592px);
    background-color: $color-white;
    border: 1px solid rgba(82, 125, 164, 0.3);
    border-radius: rem(5px);

    &__table {
        display: flex;
        flex-direction: column;
        overflow: auto;
        padding-top: rem(16px);
        overflow-y: auto;
    }

    &__header {
        display: flex;
        margin-bottom: rem(15px);
    }

    &__row {
        display: flex;
    }

    &__events-row {
        min-height: rem(300px);
    }

    &__date-row {
       
    }

    .custom-scheduler__body {
        .custom-scheduler__cell:not(:last) {
            .custom-scheduler__day-items {
            }
        }
    }

    &__event-cell:not(:last-child) {
        border-right: 1px solid $color-gray-light-2;
    }

    &__cell {
        display: flex;
        flex-direction: column;
        @include primaryFont;
        min-width: rem(152px);
        font-weight: 700;
        font-size: rem(24);
        color: $color-blue-dark-1;
    }

    &__day-items {
        display: flex;
        flex-direction: column;
        padding: rem(5px);
        height: 100%;
    }

    &__day {
        display: flex;
        justify-content: center;
        align-items: center;
        @include primaryFont;
        min-width: rem(152px);
        height: rem(38px);
        border-bottom: 1px solid $color-gray-light-2;
        padding-bottom: rem(20px);
    }

        &__day-number {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            font-size: rem(24);
            color: $color-orange-2;

            span {
                    position: relative;
                    top: 2px;
                }

            &--selected {
                background-color: $color-gray-light-2;
                border-radius: 50%;
                width: rem(36px);
                height: rem(36px);
            }
        }

    &__cell--other-month {
        .custom-scheduler__day-number {
            color: $color-gray-light-3;
        }
    }

    &__cell--header {
        width: 100%;
        display: flex;
        justify-content: center;
        @include primaryFont;
        font-weight: 300;
        font-size: rem(14);
        color: $color-blue-dark-1;
    }

    &__tbody {
        display: flex;
        flex-direction: column;
    }

    &__month-year {
        @include primaryFont;
        font-weight: 700;
        font-size: rem(24);
        color: $color-blue-dark-1;
    }

    &__day-item {
        display: flex;
        align-items: center;
        background-color: $color-white;
        height: rem(46px);
        border-left: rem(8px) solid $color-white;
        border-radius: rem(5px);
        padding: rem(7px) rem(7px) rem(7px) rem(5px);
        width: 100%;

        &:hover {
            cursor: pointer;
        }

        &__device:hover {
            cursor: unset;
        }
        
        &:not(:last-child) {
            margin-bottom: rem(8px);
        }

        &__content {
           display: flex;
           flex-direction: column;
           justify-content: space-between;
           height: 100%;
           width: 100%;
        }

        &__repeat_icon {
            display: flex;
            align-items: center;
            padding: rem(0) rem(6px);
        }

        &__hour {
            @include primaryFont;
            font-weight: 400;
            font-size: rem(10px);
            color: $color-blue-dark-2;
        }

        &__communicatoin-wrapper {
            display: flex;
            align-items: flex-end;
        }

        &__communicatoin-icon {
            display: flex;
            align-items: center;

            & > div {
                display: flex;
                align-items: center;
            }
        }

        &__communicatoin-text {
            @include primaryFont;
            font-weight: 400;
            font-size: rem(12px);
            color: $color-blue-dark-1;
            position: relative;
            margin: 0 rem(3px);
            line-height: 100%;
        }

        &--border-color-1 {
            border-color: $color-pink-1;
        }

        &--border-color-2 {
            border-color: $color-pink-1;
        }

        &--border-color-3 {
            border-color: $color-pink-1;
        }

        &--border-color-4 {
            border-color: $color-pink-1;
        }

        &--border-color-5 {
            border-color: $color-orange-2;
        }

        &--border-color-6 {
            border-color: $color-purple-light-1;
        }

        &--border-color-7 {
            border-color: $color-blue-light-1;
        }

        &--border-color-8 {
            border-color: $color-orange-2;
        }

        &--border-color-9 {
            border-color: $color-purple-light-1;
        }
        &--border-color-10 {
            border-color: $color-blue-light-5;
        }

        &--border-color-11 {
            border-color: $color-pink-1;
        }
        
        box-shadow: 0.9px 0.1rem 0.8rem rgb(15 40 76 / 8%);
    }

    ::-webkit-scrollbar {
        width: 0.2em;
        background-color: transparent;
      }
       
      ::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,0.1);
      }
      
      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
        background-color: transparent;
      }
}

.scheduler-navigator {
    display: flex;
    width: 100%;
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: rem(46px);
        background-color: $color-gray-light-4;
    }
    &__month-year {
        @include primaryFont;
        font-weight: 700;
        font-size: rem(14px);
        color: $color-blue-dark-1;
    }
    &__arrow-btn {
        width: rem(32px);
        height: rem(72px);
        font-size: rem(13px);
        position: relative;
        top: rem(76px);
        border: none;
        cursor: pointer;
        z-index: 10;
        
        &--right {
            background: linear-gradient(275.72deg, #FFFFFF 5.59%, rgba(255, 255, 255, 0) 450.07%);   
        }
        &--left {
            background: linear-gradient(94.28deg, #FFFFFF 5.59%, rgba(255, 255, 255, 0) 450.07%);
        }
    }
}
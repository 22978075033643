.card-container {
    @include primaryFont;
    border-radius: rem(5px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(24px);
    width: rem(649px);
    padding: rem(33px) rem(28px) rem(38px);
        background: $color-white;
        box-shadow: 0px 2px 15px rgba(89, 123, 142, 0.15);
        margin-bottom: rem(16px);
    
        &__title {
            font-weight: 600;
            font-size: rem(18px);
            width: rem(210px);
            height: rem(22px);
            color: $color-blue-dark-1;
            flex: none;
            order: 0;
            flex-grow: 0;
        }
    
        &__sub-title {
            font-weight: 400;
            font-size: rem(14px);
            display: flex;
            color: $color-blue-dark-1;
            margin-top: rem(-23px);
        }
    
        &__language {
            @include cardContarinerDefault;
            color: $color-orange-1;
            font-weight: 700;
        }
    
    &__dropdown-title {
        @include cardContarinerDefault;
        font-weight: 400;
        opacity: 0.8;
        padding-bottom: rem(12px);
    }

    &__dropdown {
        width: rem(199.08px);
        height: rem(40px);
        font-weight: 400;
        font-size: rem(14px);
        text-transform: capitalize;
        color: $color-gray-light-3;
        flex: none;
        order: 0;
        flex-grow: 0;

        .p-dropdown-label.p-inputtext {
                display: flex;
                align-items: center;
                font-size: rem(14px);
            }
        }
    &__dropdown-panel.p-dropdown-panel  {
        .p-dropdown-item {
            font-size: rem(14px);
            font-weight: 400;
            color: rgba($color-blue-dark-1, .6);
        }
        .p-dropdown-item.p-highlight {
            color: $color-blue-dark-1;
        }
    }
}

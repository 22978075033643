.near-bed-scheduler-view {
    border-radius: rem(5px);
    background-color: $color-white;
    height: 100%;
    padding: 0 rem(24px);
    display: flex;
    flex-direction: column;

    &__title-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        flex: 0 0 rem(82px);
    }

    &__title {
        @include primaryFont;
        font-size: rem(28px);
        font-weight: 700;
        color: $color-blue-dark-1;
    }

    &__empty {
        @include primaryFont;
        font-size: rem(24px);
        font-weight: 700;
        color: $color-gray-light-5;
        text-align: center;
    }

    &__empty-popup {
        @include primaryFont;
        font-size: rem(24px);
        font-weight: 700;
        color: $color-gray-light-5;
        text-align: center;
        padding: rem(100px) 0;
    }
    
    &__no-scheduled-icon {
        padding-bottom: rem(30px);
    }

    &__communications-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        position: relative;
        align-items: center;
    }

    &__items {
        list-style: none;
        width: 90%;
        overflow-y: auto;
        padding: rem(0.75px) rem(10px) rem(10px) 0;
        // position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        flex: 1 1;

        &__daily-scheduled {
            width: unset;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        // padding: 0 rem(10px);

        &:not(:last-child) {
            margin-bottom: rem(24px);
        }
    }
    &__icon {

    }
    &__line {
        width: rem(28px);
        height: 2px;
        background-color: black;

        &--1 {
            background-color: $color-pink-1;
        }

        &--2 {
            background-color: $color-pink-1;
        }

        &--3 {
            background-color: $color-pink-1;
        }

        &--4 {
            background-color: $color-pink-1;
        }

        &--5 {
            background-color: $color-orange-2; 
        }

        &--6 {
            background-color: $color-purple-light-1; 
        }

        &--7 {
            background-color: $color-blue-light-1; 
        }

        &--8 {
            background-color: $color-orange-2; 
        }

        &--9 {
            background-color: $color-purple-light-1; 
        }

        &--10 {
            background-color: $color-blue-light-1; 
        }

        &--11 {
            background-color: $color-pink-1;
        }
    }
    &__information-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba($color-white, 0.8);
        box-shadow: 0px 0px 4px rgb(15 40 76 / 26%);
        padding: rem(10px) rem(12px);
        height: rem(108px);
        flex: 1;
        
        &-dialog {
            padding: rem(20px) rem(190px) rem(20px) rem(20px);

            &--next {
                border: 0.1rem solid #00b4cd80;
                border-radius: 2px;
                background-color: #00b4cd29;
            }
        }
    }
    &__information {

    }
    &__eye-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 rem(16px);
    }
    &__date, &__duration {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(22px);
        color: rgba($color-blue-dark-1, 0.6);
    }
    &__text {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(22px);
        color: $color-blue-dark-1;
    }
    &__daily-schedule {
        @include primaryFont;
        font-weight: 700;
        font-size: rem(24px);
        color: $color-blue-light-1;
        display: flex;
        align-items: center;
        display: flex;
        justify-content: flex-end;
        padding: rem(10px) rem(20px) rem(20px) 0;
        
        &-text {
            cursor: pointer;
        }
    }
}
.user-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: rem(10px) 0 rem(20px) 0;
    border-bottom: 1px solid $color-gray-light-3;
  }
  &__info-box {
    padding: 0 rem(5px);
  }
  &__profile-image {
    display: flex;
    width: rem(35px);
    height: rem(32px);
    background-color: $color-blue-light-1;
    color: $color-white;

    img {
      width: rem(35px);
      height: rem(32px);
      border: rem(1.5px) solid $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__name {
    @include h5SmallBold;
  }
  &__email {
    @include primaryFont;
    font-weight: 300;
    font-size: rem(14px);
    line-height: rem(17px);
  }
  &__bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: rem(7px);
  }
  &__list {
    list-style: none;
    display: flex;
    flex-direction: column;
  }
  &__item {
    padding: rem(10px) 0;
    display: inline;
    @include primaryFont;
    font-weight: 300;
    font-size: rem(14px);
    line-height: rem(17px);

    &:hover {
      color: $color-blue-light-1;
      cursor: pointer;
    }

    &:last-child {
      font-weight: 600;
      padding-bottom: 0;
    }
  }
  &__overview-panel.p-overlaypanel {
    border: 1px solid $color-blue-light-1;
    min-width: rem(210px);
  }
  &__overview-panel.p-overlaypanel::before,
  &__overview-panel.p-overlaypanel::after {
    display: none;
  }
}

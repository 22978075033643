.form {
    display: flex;
    flex-direction: column;
    padding: 0 rem(0.5px);
    width: 100%;

    &__conatainer {
        display: flex;
        flex-direction: column;
    }

        &__row {
            display: flex;
    
            &:not(:last-child) {
                margin-bottom: rem(30px);
            }
    
            &--repeat-days {
                .med-checkboxes {
                    &__elements {
                        display: flex;
                        flex-wrap: wrap;
    
                        .med-checkbox {
                            flex-basis: 25%;
                            box-sizing: border-box;
                            padding: 3px;
                        }
                    }
                }
    
            }
        }

    &__row-wrapper {
        display: flex;
        flex-direction: column;
    }

    &__input {
        &:not(:last-child) {
            margin-right: rem(19px);
        }
    }

    &__dropdown:not(:last-child) {
        margin-right: rem(19px);
    }

    &__footer {
        display: flex;
        justify-content: end;
        align-items: center;

        &__cancel-button {
            margin-right: rem(20px);
        }

        &.buttons-block {
            margin-top: rem(30px);
            justify-content: space-between;

            .right-part {
                justify-content: flex-end;
            }

            .button-wide {
                width: auto;
            }
        }
    }
    &__sub-title {
        @include primaryFont;
        font-weight: 700;
        font-size: rem(14px);
        color: $color-orange-1;
        margin-bottom: rem(12px);  
    }
}

.create-event-form {
    min-width: rem(590px);
    &__dropdown-item-wrapper {
        display: flex;
        align-items: center;
    }

    &__dropdown-text {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(14px);
        color: $color-blue-dark-1;
        margin: 0 rem(12px);  
    }

    &__confirm_message {
        width: rem(320px);
        margin-bottom: rem(25px);
    }

    &__confirm {
        min-width: 0;
    }
}

.create-patient {
    width: 100%;
}
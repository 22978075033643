.sleep-dialog {
    @include primaryFont;
    width: rem(1000px);
    max-width: rem(1000px);
    z-index: 1200;

    &__title-text{
        color: $color-blue-dark-1;
        text-align: center;
        font-size: rem(46px);
        font-weight: 700;
        margin-bottom: rem(30px);
    }

    .p-dialog-header {
        padding: 1.5rem;
        border-bottom: 1px solid var(--border-color);
        background-color: $color-gray-light-4;
        height: rem(82px);
    }

    &__title {
        display: flex;
        align-items: center;
        gap: 1rem;

        svg {
            width: 24px;
            height: 24px;
            color: var(--primary-color);
        }
    }

    &__icon {
        margin: rem(20px) 0;
    }

    &__content-wrapper {
        padding: rem(45px);
        text-align: center;
        
        p {
            margin: 1rem 0;
            line-height: 1.5;
        }
    }

    &__time {
        color: $color-blue-dark-1;
        font-size: 32px;
        font-weight: 700;
        line-height: 55px;
    }

    &__instructions {
        color: $color-blue-dark-1;
        font-size: 32px;
        line-height: 55px;
    }
}
.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .form__input:not(:last-child) {
        margin-right: 0;
    }

    .form__input:not(:last-child) {
        margin-bottom: rem(20px);
    }

    &__field-set {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: rem(343px);
        height: rem(517px);
        align-items: center;
        background: $color-white;
        box-shadow: 0px 2px rem(15px) rgba($color-blue-dark-1, 0.15);
        border-radius: rem(10px);
        padding: rem(46px);
    }
    
    &__saml {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: rem(393);
        align-items: center;
        background: $color-white;
        box-shadow: 0px 2px rem(15px) rgba($color-blue-dark-1, 0.15);
        border-radius: rem(10px);
        padding: rem(25) rem(46px);
    }
    
    &__hidden-field {
        display: none;
    }

    &__icon-box {
        height: 100%;
        padding: rem(0px) rem(30px) rem(30) rem(30px);
    }

    &__saml-description-box {
        align-items: flex-start;
        padding-top: rem(15);
        padding-bottom: rem(30);
    }

    &__saml-description {
        @include cardContarinerDefault;
        @include text;
        padding: 0;
    }

    &__eyecontrol-saml {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(14px);
        color: $color-blue-light-4;
        align-items: center;
        top: rem(-14px);
        left: rem(7px);
        cursor: pointer;
        text-decoration: underline;
    }

    &__fields {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

    &__title {
        @include primaryFont;
        font-weight: 700;
        font-size: rem(34px);
        color: $color-blue-dark-1;
        margin-bottom: rem(40px);
    }
    &__button.button {
        width: rem(251px);
    }

    &__saml-button.button {
        width: rem(290);
        height: rem(55)
    }

    &__error {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(14px);
        color: $color-alert;
    }
}
.patient-action-menu {
    display: flex;
    justify-content: center;
    align-content: center;
    cursor: pointer;

    &__overview-panel.p-overlaypanel {
        min-width: rem(210px);
        border: 1px solid $color-blue-light-1;
    }

    &__overview-panel.p-overlaypanel::before,
    &__overview-panel.p-overlaypanel::after {
        display: none;
    }

    &__overview-panel {
        &.p-overlaypanel .p-overlaypanel-content {
            padding: 0;
        }
    }

    &__near-bed-buttons-wrapper {
        position: relative;
        top: rem(-55px);
    }
    
    &__overview-near-bed-panel {
        width: rem(200px);
        position:absolute;
        margin-top: 0;
        // top: rem(50);
        &.p-overlaypanel .p-overlaypanel-content {
            padding: 0;
        }
        background:none;
        box-shadow: none;
    }

    &__near-bed-panel__small-tablet {
        width: rem(200px);
        position:absolute;
        margin-top: rem(-10px);
        background:none;
        box-shadow: none;
        padding-inline-end: rem(10px);
        
        &.p-overlaypanel .p-overlaypanel-content {
            padding: 0;
        }
    }

    &__items {
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    &__item {
        padding: rem(14.5px) rem(10px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        span {
            @include primaryFont;
            font-weight: 300;
            font-size: rem(14px);
            color: $color-black;
        }
        &--bordered {
            border-top: 1px solid $color-gray-light-2;
            border-bottom: 1px solid $color-gray-light-2;
        }

        &--navigate {

            svg {
                position: relative;
                margin: 0 1rem;
                height: 1rem;
                right: -5px;
                height: rem(9px);
            }
        }
    }

    &__item-name {
        &--sub-title {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 rem(3px);
            svg {
                position: relative;
                height: rem(9px);
                top: -2px;
                left: 1px;
            }
        }
    }

    &__sub-icon {
        position: relative;
        top: 2px;
        right: 4px;
    }
    &__sub-title-text {
        padding: 0 rem(4px);
    }
    &__arrow {
        display: none;
    }
    &__item:hover {
        background-color: $color-blue-transparent-1;
        span {
            font-weight: 400;
        }
        .patient-action-menu__arrow {
            display: flex;
        }
    }
}

.p-overlaypanel:after {
    display: none;
}
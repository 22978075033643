.med-input {
    display: flex;
    flex-direction: column;
    &__label {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(14px);
        color: $color-blue-dark-1;
        margin-bottom: rem(5px);
    }
    &__input {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(14px);
        padding: rem(11px) rem(12px);
        width: rem(222px);
        height: rem(42px);
        &::placeholder {
                color: $color-gray-light-3;
            }
        &.p-inputtext:enabled:focus {
            border-color: $color-blue-light-1;
            box-shadow: 0px 0px 8px rgba(0, 180, 205, 0.2);
        }
        &.p-inputtext:enabled:hover {
            border-color: $color-blue-light-1;
            box-shadow: 0px 0px 8px rgba(0, 180, 205, 0.2);
        }
    }
}

.med-dropdown {
    display: flex;
    flex-direction: column;

    &__label {
        @include formLabel;
    }

    &__dropdown {
        @include primaryFont;
        width: rem(237px);
        height: rem(42px);
        font-weight: 400;
        font-size: rem(14px);
        color: $color-gray-light-3;
        flex: none;
        order: 0;
        flex-grow: 0;

        .p-dropdown-label.p-inputtext {
            @include primaryFont;
            display: flex;
            align-items: center;
            font-size: rem(14px);
        }

        .p-dropdown-label.p-inputtext.p-placeholder {
            color: $color-gray-light-3;
        }

        &.input-error__wrapper.p-dropdown:not(.p-disabled):hover {
            border-color: $color-alert;
        }

        &.input-error__wrapper.p-dropdown:not(.p-disabled).p-focus {
            border-color: $color-alert;
            box-shadow: 0 0 0 0.2rem rgba($color-alert, 0.15);
        }
        &.p-dropdown:not(.p-disabled).p-focus {
            border-color: $color-blue-light-1;
            box-shadow: 0px 0px 8px rgba(0, 180, 205, 0.2); 
        }
        &.p-dropdown:not(.p-disabled):hover {
            border-color: $color-blue-light-1;
            box-shadow: 0px 0px 8px rgba(0, 180, 205, 0.2); 
        }
    }

    &__dropdown-panel.p-dropdown-panel {
        .p-dropdown-item {
            @include primaryFont;
            font-size: rem(14px);
            font-weight: 400;
            color: rgba($color-blue-dark-1, .6);
        }

        .p-dropdown-item.p-highlight {
            color: $color-blue-dark-1;
        }
    }
}

.input-error {
    position: relative;
    width: 100%;
    height: rem(10px);

    &__wrapper {
        border-color: $color-alert;
        box-shadow: 0 0 0 0.2rem rgba($color-alert, 0.15);
        &.p-inputtext:enabled:hover {
            border-color: $color-alert;
            box-shadow: 0 0 0 0.2rem rgba($color-alert, 0.15);
        }
        &.p-inputtext:enabled:focus {
            border-color: $color-alert;
            box-shadow: 0 0 0 0.2rem rgba($color-alert, 0.15);
        }
    }

    &__text {
        color: $color-alert;
        position: absolute;
        left: 1px;
        top: 5px;
    }
}

.med-calendar {
    display: flex;
    flex-direction: column;

    input {
        @include primaryFont;
        font-size: rem(14px);
        font-weight: 400;
        color: $color-blue-dark-1;
        padding-left: rem(31px);
    }

    .p-inputtext:enabled:hover {
        border-color: $color-blue-light-1;
        box-shadow: 0px 0px 8px rgba(0, 180, 205, 0.2); 
    }

    .p-inputtext:enabled:focus {
        border-color: $color-blue-light-1;
        box-shadow: 0px 0px 8px rgba(0, 180, 205, 0.2); 
    }


    .p-calendar.p-component.p-inputwrapper {
        
        width: rem(237px);
        height: rem(42px);
    }


    &--time {

        .p-calendar.p-component.p-inputwrapper::before {
            content: url('../svgs/time.svg');
            position: absolute;
            top: 56%;
            left: 7%;
            transform: translate(-50%, -50%);
        }
    }

    &--date {
        .p-calendar.p-component.p-inputwrapper::before {
            content: url('../svgs/calendar.svg');
            position: absolute;
            top: 52%;
            left: 7%;
            transform: translate(-50%, -50%);
        }
    }

    &__label {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(14px);
        color: $color-blue-dark-1;
        margin-bottom: rem(5px);
    }
}

.med-checkbox {

    display: flex;
    align-items: center;
    

    label {
        margin: 0 rem(10px);
        font-size: rem(14px);
        color: $color-blue-dark-1;
        font-weight: 400;
    }
    .p-checkbox-box {
        border: rem(2px) solid $color-blue-light-1;
    }

    .p-checkbox .p-checkbox-box.p-highlight {
        border: rem(2px) solid $color-blue-light-1;
    }
    
    .p-checkbox .p-checkbox-box {
        border: rem(2px) solid $color-blue-light-1;
        background-color: $color-white;
        border-radius: rem(4px);
    }
    .p-checkbox .p-checkbox-box.p-highlight {
        border: rem(2px) solid $color-blue-light-1;
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border: rem(2px) solid $color-blue-light-1;
        background-color: $color-white;
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        box-shadow: unset;
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box {
        border: rem(2px) solid $color-blue-light-1;
        background-color: $color-white;
    }

    .p-checkbox-box .p-checkbox-icon {
        transform: scale(0.9);
        background-color: $color-white;
        color: $color-blue-light-1;
    }

    .p-checkbox .p-checkbox-box.p-highlight {
        border: rem(2px) solid $color-blue-light-1;
    }
}

.med-radio-buttons {
    display: flex;
}

.med-radio-button {
    display: flex;
    .p-radiobutton {
        vertical-align: baseline;
    }

    label {
        margin: 0 rem(8px);
    }

    .p-radiobutton .p-radiobutton-box {
        border-color: $color-blue-light-1;
        background-color: $color-white;
        height: rem(20);
        width: rem(20);
    }

    .p-radiobutton-box .p-radiobutton-icon {
        background-color: $color-blue-light-1;
        height: rem(12);
        width: rem(12);
    }

    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: $color-blue-light-1;
        background: #ffffff;
        color: #ffffff;
    }

    .p-radiobutton .p-radiobutton-box.p-highlight {
        border-color: $color-blue-light-1;
    }

    .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
        border-color: $color-blue-light-1;
    }
}
// COLORS.
$color-gray-light-1: #B6B6B6;
$color-gray-light-2: #E0E0E0;
$color-gray-light-3: #BDBDBD;
$color-gray-light-4: #f9f8f8;
$color-gray-light-5: #A0ACC5;
$color-gray-light-6: #CEDFEA;
$color-gray-dark-1: #787A7C;
$color-gray-dark-2: #6C7C92;
$color-blue-light-1: #00B4CD;
$color-blue-light-2: #86D9BC;
$color-blue-light-3: #527DA4;
$color-blue-light-4: #1713F4;
$color-blue-light-5: #D6E7F0;
$color-blue-light-6: #719AD6;
$color-blue-dark-1: #0F284C;
$color-blue-dark-2: #455472;
$color-blue-dark-3: #1C2D5A;
$color-blue-dark-4: #1995DA;
$color-blue-transparent-1: #70b3bc1f;
$color-purple-light-1: #AEA0FA;
$color-purple-light-2: #ECEBF4;
$color-purple-light-3: #B6A9F9;
$color-pink-1: #FD86DD;
$color-pink-2: #FFDFF6;
$color-orange-1: #BB7756;
$color-orange-2: #FFAC80;
$color-orange-3: #FFD5BE;
$color-action: #BE3535B2;
$color-white: #fff;
$color-black: #000;
$color-alert: #D23A3A;
$skeleton-color: #0f284c14;

$color-notification-border: rgba(15, 40, 76, 0.16);
$color-transparent: rgba(255, 255, 255, 0);

// FONT
$default-font-size: rem(16);
.patient-side-bar {
    &__items {
        list-style: none;
    }

    &__item {
        display: flex;
        background-color: $color-white;
        height: rem(90px);
        border-left: rem(8px) solid $color-white;
        border-radius: rem(5px);
        border-bottom: rem(1px) solid #0f284c12;
        border-right: rem(1px) solid #0f284c12;

        &:hover {
            cursor: pointer;
        }

        &.patient-side-bar__active-item {
            background-color: $color-blue-dark-1;
            .patient-side-bar__number {
                color: $color-white;
            }
            .patient-side-bar__name-box {
                color: $color-white;
            }
        }
        &.patient-side-bar__alert {
            border-left: rem(8px) solid $color-alert;
        }
    }

    &__eye-box {
        padding: rem(30px) rem(5px); 
    }

    &__general-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: rem(24px) rem(25px) rem(24px) rem(10px);
    }

    &__wifi-icon {
        display: flex;
        align-items: center;
        .wifi-icon__off {
            font-size: rem(13px);
        }
        svg {
            width: rem(13px);
        }
    }

    &__oop-icon {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 2px;
        top: 1px;
        svg {
            width: rem(13px);
        }
    }

    &__name-box {
        color: $color-blue-dark-1;
        @include primaryFont;
        font-weight: 400;
        font-size: rem(16px);
    }

    &__last-name {
        padding: 0 4px;
    }

    &__information {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__room-box {
        display: flex;
        align-items: center;
    }

    &__icons {
        display: flex;
    }

    &__bed-wrapper {
        display: flex;
        align-items: center;
        padding: 0 rem(15px);
    }

    &__room-wrapper {
        display: flex;
        align-items: center;
    }

    &__number {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(14px);
        color: $color-blue-dark-1;
        margin: 0 rem(2px);
    }

    &__small-icon {
        position: relative;
        top: -1px;
        display: flex;
        align-items: center;
    }

    &__cable-connection {
        display: flex;
        align-items: center;
        padding: 0 2px;
    }
}
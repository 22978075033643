.attention-content {
    display: flex;
    align-items: center;
    max-width: rem(580px);
    &__content-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__title {
        display: flex;
        align-items: center;
    }
    &__title-text {
        margin: 0 rem(10px);
        @include primaryFont;
        font-weight: 700;
        font-size: rem(28px);
        color: $color-blue-dark-1;
    }
    &__text {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(24px);
        color: $color-blue-dark-1;
        margin: 0 rem(32px);
    }
    &__header.p-dialog-header.p-dialog-header {
        background-color: $color-gray-light-4;
        height: rem(82px);
    }
    &__dialog.custom-dialog {
        width: rem(725px);
        height: rem(294px);
    }

    &__column {
        flex-direction: column;
    }
}
// MEDIA QUERY MANAGER
/*
0 - 600px: Phone
600 - 900px: Tablet portrait
900 - 1200px: Tablet landsacape
1200 - 1800px: Normal styles
1800 - bigger: Big desktop

$breakpoint argument choices:
- Phone
- tab-port
- tab-land
- big-desktop
*/

// 1em = 16px

@mixin respond($breakpoint) {
  @if $breakpoint == small-phone {
    @media only screen and (max-width: 30em) {
      @content;
    }
  }
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    }
  }
}

@mixin respondOrientation($orientation) {
  @if $orientation == port {
    @media (orientation: portrait) {
      @content;
    }
  }
}

@mixin centerHorizontalVertical {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin primaryFont {
  font-family: "Lato";
  font-style: normal;
}

@mixin formLabel {
  @include primaryFont;
  font-weight: 400;
  font-size: rem(14px);
  color: $color-blue-dark-1;
  margin-bottom: rem(5px);
}

@mixin displayExtraBold {
  @include primaryFont;
  font-weight: 800;
  font-size: rem(60px);
}

@mixin h1Bold {
  @include primaryFont;
  font-weight: 800;
  font-size: rem(48px);
}

@mixin h1SmallBold {
  @include primaryFont;
  font-weight: 800;
  font-size: rem(34px);
}

@mixin h2Bold {
  @include primaryFont;
  font-weight: 700;
  font-size: rem(34px);
}

@mixin h2SmallBold {
  @include primaryFont;
  font-weight: 700;
  font-size: rem(24px);
}

@mixin h3Bold {
  @include primaryFont;
  font-weight: 700;
  font-size: rem(24px);
}

@mixin h3SmallBold {
  @include primaryFont;
  font-weight: 700;
  font-size: rem(20px);
}
@mixin h4Bold {
  @include primaryFont;
  font-weight: 700;
  font-size: rem(18px);
}

@mixin h4SmallBold {
  @include primaryFont;
  font-weight: 700;
  font-size: rem(16px);
}
@mixin h5Bold {
  @include primaryFont;
  font-weight: 700;
  font-size: rem(20px);
}

@mixin h5SmallBold {
  @include primaryFont;
  font-weight: 700;
  font-size: rem(16px);
}

@mixin h6Bold {
  @include primaryFont;
  font-weight: 700;
  font-size: rem(14px);
}

@mixin h6SmallBold {
  @include primaryFont;
  font-weight: 700;
  font-size: rem(12px);
}

@mixin input-label {
  @include cardContarinerDefault;
  @include text;
  padding-left: rem(3);
}

@mixin cardContarinerDefault {
  font-size: rem(14px);
  color: $color-blue-dark-1;
}

@mixin text {
  font-weight: 400;
  opacity: 0.8;
  padding-bottom: rem(12px);
}

@mixin headerWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@mixin patientViewInformation {
  background-color: $color-white;
  padding: rem(12px) rem(14px);
  box-shadow: 0px 2px 15px rgba($color-blue-dark-1, 0.15);
  height: 100%;  
}

@mixin flexSpaceBetweenCenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
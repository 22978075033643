@use "sass:math";
@import "../_base.scss";
@import "../external.scss";
@import "./authentication/Login.scss";
@import "./shadow-updater/ShadowUpdater.scss";
@import "./sqs-message-sender/SqsMessageSender.scss";
@import "./general/Navbar.scss";
@import "./general/CardsContainer.scss";
@import "./general/UserMenu.scss";
@import "./pages/SettingsPage.scss";
@import "./pages/NearBedViewPage.scss";
@import "./general/SideBar.scss";
@import "./general/ScrollingSidebar.scss";
@import "./Overview.scss";
@import "./general/settings/GeneralSettings.scss";
@import "./general/settings/DeviceEarphoneVolume.scss";
@import "./general/settings/BroadcastMethod.scss";
@import "./general/ShareLinkContent.scss";
@import "./patientView/PatientInformationBar.scss";
@import "./pages/PatientViewPage.scss";
@import "./patientView/PatientSideBar.scss";
@import "./patientView/RecordingsList.scss";
@import "./patientView/PatientInformationScreen.scss";
@import "./patientView/PatientActionMenu.scss";
@import "./patientView/CommunicationLog.scss";
@import "./patientView/Notifications.scss";
@import "./patientView/RecordingSlots.scss";
@import "./nearBedView/NearBedSchedulerView.scss";
@import "./nearBedView/CurrentCommunicationView.scss";
@import "./nearBedView/NoPatientPage.scss";
@import "./general/CustomDialog.scss";
@import "./Icons.scss";
@import "./Buttons.scss";
@import "./ShareLink.scss";
@import "./SleepDialog.scss";
@import "./VideoStreamBox.scss";
@import "./CustomComponents.scss";
@import "./FormComponents.scss";
@import "./deviceView/DeviceInformationBar.scss";
@import "./deviceView/DeviceSettingsHeader.scss";

@import "./general/settings/OopIndication.scss";
@import "./general/CustomScheduler.scss";
@import "./general/settings/SchedulerCard.scss";
@import "./general/ToastHandler.scss";
@import "./general/CustomTable.scss";
@import "./general/DeviceList.scss";
@import "./general/CustomAccordion.scss";
@import "./general/AttentionContent.scss";
@import "./general/DailyScheduledContent.scss";
@import "./general/ResponsesContent.scss";

// components
.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  flex: 1;
  background-image: url( '../svgs/Bg\ decoration.svg' );
  background-repeat: no-repeat;
  background-size: 120%;
  background-position-y: 12%;
  background-position-x: -12rem;
  width: 100%;
}

.header {
  color: $color-blue-dark-1;
  font-size: 5rem;
  display: flex;
  flex: 0 0 6rem;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba($color-blue-dark-2, 0.4);

  &__img {
    height: 4rem;
  }
}

.device {
  color: $color-blue-dark-1;
  font-size: 2rem;
}

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.welcome {
  @include displayExtraBold;
}

.scrolling-box {
  color: #fff;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0f284c4b;
  padding: rem(15px) rem(40px);
  margin-bottom: rem(20px);
  height: rem(350px);
  width: 64.9rem;
}

.skeleton {
  background-color: $skeleton-color;
}
.current-communication-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: rem(5px);
    background-color: $color-white;
    height: 100%;

    &__title-wrapper {
        display: flex;
        width: 100%;
        padding: rem(24px) rem(24px) rem(10px) rem(4px);
        align-items: center;
    }

    &__title {
        @include primaryFont;
        font-size: rem(28px);
        font-weight: 700;
        color: $color-blue-dark-1;
        padding: 0 rem(20px);
    }

    &__communication-name {
        @include primaryFont;

        font-size: rem(24px);
        font-weight: 400;
        color: rgba($color-blue-dark-1, .6);
        padding: 0 rem(20px);
    }

    &__line {
        background-color: $color-gray-light-2;
        width: 1px;
        height: rem(20px);
    }

    &__playing {
        &-wrapper {
            padding-bottom: rem(20px);
        }

        &-animation {
            width: 100%;
            height: rem(180px);
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            padding-bottom: rem(5px);
        }

        &-gif {
            width: rem(500px);
            height: rem(80px);
            background-image: url('../../../gifs/PlayingCommunication.gif');
            background-size: contain;
        }
    }   

    &__big-icon {
        position: absolute;
        z-index: 10;
    }

    &__progress-bar {
        width: 0%;
        height: rem(10px);
        background-color: #828D9E;
        transition: width 0.3s ease;

        &-background {
            width: rem(500px);
            background-color: $color-gray-light-6;
            border-radius: rem(5px);
        }

        &-time {
            @include primaryFont;
            
            color: $color-blue-dark-1;
            font-weight: 400;
            font-size: rem(24px);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__stop-button {
        position: relative;
        bottom: rem(20px);
        
        &-content {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        
        &-text {
            padding: rem(6px) rem(10px);
            font-weight: 700;
            font-size: rem(24px);
            line-height: rem(28.8px);
        }
    }
}
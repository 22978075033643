.communication-log {
    @include patientViewInformation;

    @include respond(tab-land) {
        height: rem(400px);
        margin-bottom: rem(20px);
    }

    &__items {
        list-style: none;
        width: 100%;
        height: 90%;
        overflow-y: auto;
        padding: rem(5px) rem(7px);
    }
    &__header {
        @include primaryFont;
        font-weight: 600;
        font-size: rem(18px);
        color: $color-blue-dark-1;
        padding-bottom: rem(12px);
    }
    &__item {
        display: flex;
        width: 100%;
        align-items: center;
        height: rem(62px);

        &:not(:last-child) {
            margin-bottom: rem(16px);
        }
    }
    &__icon {

    }
    &__line {
        width: rem(12px);
        height: 1px;
        background-color: black;

        &--1 {
            background-color: $color-pink-1;
        }

        &--2 {
            background-color: $color-pink-1;
        }

        &--3 {
            background-color: $color-pink-1;
        }

        &--4 {
            background-color: $color-pink-1;
        }

        &--5 {
            background-color: $color-orange-2; 
        }

        &--6 {
            background-color: $color-purple-light-1; 
        }

        &--7 {
            background-color: $color-blue-light-1; 
        }

        &--8 {
            background-color: $color-orange-2; 
        }

        &--9 {
            background-color: $color-purple-light-1; 
        }

        &--10 {
            background-color: $color-blue-light-1; 
        }

        &--11 {
            background-color: $color-pink-1;
        }
    }
    &__information-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: rgba($color-white, 0.8);
        box-shadow: 0px 0px 4px rgb(15 40 76 / 26%);
        padding: rem(10px) rem(12px);
    }
    &__information {

    }
    &__eye-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 rem(16px);
    }
    &__date {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(12px);
        color: rgba($color-blue-dark-1, 0.6);
    }
    &__text {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(14px);
        color: $color-blue-dark-1;
    }
}
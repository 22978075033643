.patient-view-page {
    flex: 1;
    display: flex;
    flex-direction: column;

    &__header-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 0 0 rem(90px);
    }
    &__title {
        @include primaryFont;
        font-weight: 700;
        font-size: rem(24px);
        color: $color-blue-dark-1;
        padding-left: rem(30px);
    }
    &__main {
        display: flex;
        padding: 0 rem(20px);
        flex: 1;
    }
    &__side-bar {
        flex: 1 1 18%;
        padding-right: rem(16px);
    }
    &__information-screens {
        flex: 1 1 82%;
        flex-direction: column;
        display: flex;
    }
    &__action-button {
        padding: 0 rem(15px);
    }
    &__header-buttons {
        display: flex;
        align-items: center;
    }
    &__empty-message {
        @include primaryFont;
        font-weight: 700;
        font-size: rem(24px);
        color: $color-blue-dark-1;
    }
}
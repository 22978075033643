$color-placeholder: rgba(15, 40, 76, 0.6); // #0F284C99 with 60% opacity
$color-input-border: #B9BFC6; // New variable for border and placeholder color
$color-valid: #4CAF50; // Green color for valid input

.custom-dialog.link-dialog {
    max-width: rem(800px);
    min-height: rem(550px);
    width: 60%;

    &__wrapper {
        display: flex;
        text-align: center;
    }

    .p-dialog-content {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .link-dialog {
        &__header {
            background-color: $color-gray-light-4;
            height: rem(82px);
        }
        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        &__title {
            font-size: rem(32px);
            font-weight: 700;
            color: $color-blue-dark-1;
            margin-bottom: rem(20px);
        }

        &__description {
            font-size: rem(28px);
            font-weight: 400;
            color: $color-blue-dark-1;
            margin-bottom: rem(30px);
            max-width: rem(600px);
        }

        &__phone-number {
            font-weight: 700;
            font-size: rem(40px);
            line-height: rem(48px);
            text-align: center;
            color: $color-gray-dark-2;
            margin-bottom: rem(32px);
        }

        &__clickable-text{
            font-size: rem(28px);
            font-weight: 700;
            text-align: center;
            color: $color-blue-dark-1;
            cursor: pointer;
            display: inline-block;
            margin-bottom: rem(32px);
            text-decoration: underline;
        }

        &__icon {
            padding-top: rem(30px);
            padding-bottom: rem(60px);
        }

        &__loader {
            margin-bottom: rem(16px);
        }

        &__input-wrapper {
            margin-bottom: rem(30px);

            .p-inputgroup {
                align-items: stretch;
                height: rem(60px);

                .country-dropdown {
                    // width: rem(1px); // Reduced from 100px to 70px

                    .p-dropdown {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        border-right: none;

                        .p-dropdown-label {
                            padding-right: rem(8px); // Reduce right padding
                            padding-left: rem(8px);  // Reduce left padding
                        }

                        .p-dropdown-trigger {
                            width: rem(10px); // Make the dropdown arrow area smaller
                        }
                    }
                }

                .p-float-label {
                    flex: 2 1;

                    .p-inputtext {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        transition: border-color 0.2s;
                    }
                }
            }

            .p-inputtext {
                width: 100%;
                color: $color-blue-dark-1;
                font-size: rem(18px);
                padding: rem(16px);
                line-height: 1;
                display: flex;
                align-items: center;
                border-color: $color-input-border; // Using new variable

                &:focus, &.p-filled {
                    // border-color: $color-blue-light-1;
                    box-shadow: none;
                }

                &.p-dropdown-label {
                    padding-right: 0; // Reduce right padding
                }
                
                &.valid-number {
                    border-color: $color-valid;
                }
            }

            .p-float-label {
                position: relative;

                label {
                    position: absolute;
                    left: rem(16px);
                    top: 0;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    color: $color-input-border;
                    font-size: rem(18px);
                    pointer-events: none;
                    transition: all 0.2s ease;
                }

                input:focus ~ label,
                input.p-filled ~ label {
                    top: rem(-12px);
                    font-size: rem(12px);
                    height: auto;
                    background-color: white;
                    padding: 0 rem(4px);
                    color: $color-blue-light-1;
                }

                &.p-inputwrapper-focus,
                &.p-inputwrapper-filled {
                    .p-inputtext {
                        border-color: $color-blue-light-1;
                    }

                    & + .country-dropdown .p-dropdown {
                        border-color: $color-blue-light-1;
                    }
                }

                &.valid-number {
                    label {
                        color: $color-valid;
                    }
                }

                &.not-empty {
                    label {
                        color: $color-blue-light-1;
                    }
                }
            }

            .p-inputtext::placeholder {
                color: $color-input-border;
            }

            .p-inputtext:hover::placeholder {
                color: $color-input-border;
            }

            .p-error {
                display: none;
            }
        }

        &__submit-button {
            background-color: $color-blue-light-1;
            border: none;
            color: $color-white;
            font-weight: bold;
            padding: rem(16px) rem(20px);
            font-size: rem(18px);
            min-width: rem(200px);

            &:hover {
                background-color: darken($color-blue-light-1, 10%);
            }
        }
    }
}
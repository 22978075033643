.device-earphone-volume {
    display: flex;
    flex-direction: column;

    &__header-wrapper {
        @include headerWrapper;
        flex-direction: column;
    }

    &__header {
        @include primaryFont;
        font-weight: 600;
        font-size: rem(18px);
        line-height: rem(22px);
        color: $color-blue-dark-1;
        margin-bottom: rem(18px);
    }
    &__reset-button {
        @include resetButton;
    }
    &__reset-button:hover {
        cursor: pointer;
    }
    &__sub-title {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(14px);
        line-height: rem(20px);
        color: $color-blue-dark-2;
        margin-bottom: rem(18px);
    }
    &__box {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    &__volume-title {
        margin: 0 3px;
    }
    &__slider-wrapper {
        width: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__volume-wrapper {
        display: flex;
        width: fit-content;
        border: 1px solid #ced4da;
        border-radius: rem(6px);
        padding: rem(12px) rem(20px);
        margin-bottom: rem(65px);
        div {
            @include primaryFont;
            font-weight: 400;
            font-size: rem(14px);
            line-height: rem(20px);
            color: $color-orange-1;
        }
    }
    &__volume-value {
        flex: 1;
    }
    &__icon {
        display: flex;
        align-items: center;
    }
    &__slider {
        flex: 1;
        padding: rem(6px) rem(19px) rem(6px) rem(9px);
        .p-slider {
            height: rem(6px);
        }
        .p-slider .p-slider-handle {
            height: rem(20px);
            width: rem(20px);
            background: #ffffff;
            border: 3px solid $color-blue-light-1;
        }
        .p-slider:not(.p-disabled) .p-slider-handle:hover {
            background: $color-blue-light-1;
            border-color: $color-blue-light-1;
        }
        .p-slider .p-slider-range {
            background: $color-blue-light-1;
            border-radius: 6px;
        }
        .p-slider .p-slider-handle {
            top: 0;
        }
    }
}

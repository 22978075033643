.overview {
  padding-left: rem(17);
  padding-right: rem(31);
  flex: 1;

  &__top {
    display: flex;
    justify-content: space-between;
    padding-top: rem(21);
    padding-bottom: rem(29);
  }

  &__headline {
    @include primaryFont;
    font-weight: 700;
    font-size: rem(24);
    color: $color-blue-dark-1;
  }

    &__table {
      border: none;
      background: $color-gray-light-4;
  
  
  
      &-alert {
        box-shadow: rem(0) rem(6) rem(25) rgba(89, 123, 142, 0.16);
        border-radius: rem(9);
  
        .overview__alert-column {
          left: 0;
          top: 0;
          position: absolute;
          height: 100%;
          width: rem(8px);
          background: $color-alert;
          border-radius: rem(2);
        }
      }
  
    }
  &__alert-wrapper-column {
    position: relative;
    width: rem(8);
  }

  &__table-row {
    height: rem(66px);
    cursor: pointer;
  }

  &__data {
    @include primaryFont;
    font-weight: 400;
    font-size: rem(12px);
    color: $color-blue-dark-1;
    text-align: center;
    vertical-align: middle;
    &--centered {
      display: flex;
      justify-content: center;
    }
  }

  .p-datatable .p-datatable-tbody>tr.overview__table__alert {
    background: rgba(180, 142, 126, 0.05);
  }

  .p-datatable-emptymessage {
    td {
      @include primaryFont;
      font-weight: 400;
      font-size: rem(22px);
      color: $color-blue-dark-1;
      text-align: center;
    }
  }

  &__icons-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__icons>* {
    padding-left: 0.5rem;
  }

  &__icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    right: rem(25px);
    width: rem(160px);
  }
}

.p-datatable-thead {
  @include primaryFont;
  border-radius: rem(5px);
  height: rem(46px);
}

.p-column-title {
  @include primaryFont;
  font-weight: 400;
  font-size: rem(12);
  color: $color-gray-dark-1;
}

.p-column-header-content {
  text-align: center;
  justify-content: center;
}
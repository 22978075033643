.scheduler-card {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__title-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__title {
    @include primaryFont;
    font-weight: 700;
    font-size: rem(18px);
    color: $color-blue-dark-1;
  }

  &__sub-title {
    @include primaryFont;
    font-weight: 400;
    font-size: rem(14px);
    color: $color-blue-dark-1;
    max-width: rem(410px);

  }

  &__button-wrapper {}

  &__main {}

  &__scheduler-checkbox {
    margin-top: rem(15px);
    display: flex;
    align-items: center;
  }

}
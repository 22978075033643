.patient-information-screen {
    flex: 1;
    display: flex;
    flex-direction: column;

    &__general-information {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__patient-bar {
        flex: 0;
    }

    &__nav-bar {
        flex: 0 0 rem(32px);
    }

    &__boxes {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__top-box {
        display: flex;
        justify-content: space-between;
        padding-bottom: rem(10px);
        flex: 1;

        @include respond(tab-land) {
                flex-direction: column-reverse;
                justify-content: center;
        }
    }

    &__notifications {
        flex: 0 0 49.6%;
        border-radius: rem(5px);
        justify-content: center;
        align-items: center;
        font-size: rem(30px);
        height: rem(500px);
    }

    &__communication-log {
        flex: 0 0 49.6%;
        border-radius: rem(5px);
        height: rem(500px);
    }

    &__recording-box {
        flex: 1; 
    }
}
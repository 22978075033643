.video-stream-box {
    background-color: $color-white;
    &__content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: rem(20px);
      border: 1px solid rgba($color-blue-light-1, 0.5);
      border-radius: 5px;
    }
    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: rem(5px);
    }
    &__main {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #0f284c1f;
      border-radius: rem(7px);
      height: 100%;
      width: 100%;
    }
    &__title-wrapper {
      display: flex;
      align-items: center;
    }
    &__title {
      @include h3SmallBold;
      color: $color-blue-dark-1;
      margin: 0 rem(8px);
    }
    &__close-resize {
      cursor: pointer;
      width: rem(16px);
      height: rem(16px);
    }
        &__loading {
          @include h4SmallBold;
          color: $color-blue-dark-1;

          svg {
              width: 25%;
            }
          @keyframes p-progress-spinner-color {
    
            100%,
            0% {
              stroke: $color-blue-dark-1;
            }
    
            40% {
              stroke: $color-blue-light-1;
            }
    
            66% {
              stroke: $color-blue-dark-1;
            }
    
            80%,
            90% {
              stroke: $color-blue-light-1;
            }
          }
        }
  }
  
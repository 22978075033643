.side-bar {
  width: rem(151px);
  background-color: $color-white;

  &__items {
    flex: 1;
    list-style: none;
  }

  &__item {
    padding: rem(20px) 0 rem(20px) rem(20px);
    border-radius: rem(6px);
    cursor: pointer;
  }

  &__active-item {
    background-color: $color-blue-dark-1;
    color: $color-white;
    span {
      color: $color-white;
    }
  }

  &__item-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__arrow {
    &::after {
      display: inline-block;
      content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all 0.35s;
    }
  }

  &__arrow-down {
    &::after {
      transform: rotate(90deg);
    }
  }

  &__item-label {
    @include primaryFont;
    font-weight: 300;
    font-size: rem(14px);
    line-height: rem(17px);
    text-decoration: none;
    color: $color-blue-dark-1;
    height: 100%;
    width: 100%;
  }

  &__sub-items {
    list-style: none;
    display: none;
    transition: all 0.35s;
  }

  &__sub-item {
    margin: 1rem;
  }

  &__open-sub-items {
    display: block;
  }
}

.recording-slots {
    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  
    &__item:not(:last-child) {
      margin-bottom: 10px;
    }
  
    &__item--start {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    
    &__name {
      @include primaryFont;
      font-weight: 400;
      font-size: rem(14px);
      color: $color-blue-dark-1;
      margin: 0 rem(8px);
      min-width: rem(80px);
    }

    &__dropdown {
      padding: 0 rem(30px);
      border-left: rem(1px) solid $color-gray-light-2;
      
    }
  }
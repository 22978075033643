@import "./mixin";
@import "./functions";
@import "./variables";

@font-face {
  font-family: "Lato";
  src: url("fonts/lato/Lato-Regular.eot");
  src: url("fonts/lato/Lato-Regular.eot?#iefix") format("embedded-opentype"),
    url("fonts/lato/Lato-Regular.woff") format("woff"),
    url("fonts/lato/Lato-Regular.woff2") format("woff2"),
    url("fonts/lato/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 65%;
  box-sizing: border-box;

  @include respond(tab-land) {
    font-size: 62.5%; // 1rem = 10px
  }

  @include respond(tab-port) {
    font-size: 50%;
  }

  @include respond(phone) {
    font-size: 47%;
  }

  @include respond(small-phone) {
    font-size: 41%;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: $color-white;
  height: 100vh;
}

body:has(.p-overlaypanel):has(#root):has(.container):has(.navbar-near-bed) > *:not(.p-overlaypanel):not(.p-dialog-mask) {
  opacity: 0.4;
}

.p-overlaypanel > * {
  z-index: 1001;
  opacity: 1;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.share-link {
    display: flex;
    flex-direction: column;
    // height: 100%;
    padding: rem(20px);
  
    &__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; // This will vertically center the content
      height: 100%;
    }
  
    &__text {
        @include primaryFont;
        font-size: rem(24px);
        color: $color-blue-dark-1;
        flex: 1;
    }
  
    &__header {
      font-size: rem(28px);
      font-weight: 700;
      margin-bottom: rem(10px);
      color: $color-gray-dark-2;
    }
  
    .p-button {
        font-size: rem(24px);
        font-weight: 700;
        line-height: rem(28.8px);
    }
}
.near-bed-view-page {
  // height: 91.5vh;
  // max-height: 100vh;
  min-height: 93.5vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  // position: fixed;
  width: 100%;

  @include respondOrientation(port) {
    position: relative;
    height: 100%;
  }

  &__header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 rem(92px);
  }
  
  &__action-buttons {
    padding: 0 rem(10px);
    display: flex;
    justify-content: space-between;
   
  }

  .button-nurse {
    font-size: rem(18px);
  }

  &__action-button {
    padding: 0 rem(15px);
  }

  &__header-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__button-responses-small-tablet {
    border-radius: rem(10px);
    padding: rem(14px) rem(20px);
    margin-left: rem(10px);
    background: $color-blue-light-1;
  }

  &__all-responses {
    @include primaryFont;

    font-weight: 700;
    font-size: rem(28px);
    color: $color-blue-light-1;
    display: flex;
    align-items: center;
    top: rem(-14px);
    left: rem(7px);
    cursor: pointer;  
    display: flex;
    justify-content: center;
  }

  &__right {
    display: flex;
    flex-direction: column;
    flex: 1; 
    margin-right: rem(10px);
    // height: 100%;
  }
  
  &__communications {
    flex: 1;
    display: flex;
    flex-direction: column;
    @include respondOrientation(port) {
      margin: rem(10px);
    }
  }
  
  &__right-buttons {
    display: flex;
  }

  &__empty-popup {
    @include primaryFont;
    font-size: rem(24px);
    font-weight: 700;
    color: $color-gray-light-5;
    text-align: center;
    padding: rem(100px) 0;
  }

  &__items {
    list-style: none;
    width: 80%;
    overflow-y: auto;
    padding: rem(0.75px) rem(10px) rem(10px) 0;
    // position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    flex: 1 1;

    &__responses {
        width: unset;
    }
  }

  &__date {
    @include primaryFont;
    font-weight: 400;
    font-size: rem(22px);
    color: rgba($color-blue-dark-1, 0.6);
    min-width: fit-content;
  }

  &__hour {
    text-align: end;
  }

  &__camera {
    border-radius: 0 1rem 1rem 0;
  }

  &__content {
    display: flex;
    width: 100%;
    flex: 1;
    overflow: hidden;
    margin-top: rem(10px);

    @include respondOrientation(port) {
      flex-direction: column;
      height: auto;
      min-height: 0;
    }
  }
    
  &__content-wrapper {
    border-radius: rem(5px);
    // width: rem(50px);
    box-shadow: 0px 2px 15px rgba(15, 40, 76, 0.15);
    margin: 0 rem(10px) rem(10px) rem(10px);
    position: relative;
  }

  &__profile {
    flex: 1;
    margin-bottom: rem(10px);
    align-items: center;
    background-color: #fff;    
    padding: 1.2rem 1.4rem;
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;

    @include respondOrientation(port) {
      margin-bottom: 0;
      width: 100%;
      max-width: 100%;
      margin: rem(10px) 0;
    }
  }

  &__share-link {
    background-color: #fff;    
  }

  &__content-wrapper--end {
    // flex-grow: 1;
    margin: 0 rem(10px) rem(10px) 0;
  }

  &__content-wrapper--end:first-child {
    flex-grow: 3;
  }

  &__content-wrapper--end:last-child {
    flex-grow: 1;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    // width: 100%;
    position:absolute;
    right: rem(14px);

    &-small-tablet {
      justify-content: space-between;
      width: 100%;
    }
  }

  &__profile-wrapper {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    color: $color-blue-dark-1;
    font-family: "Lato";
    font-style: normal;
    text-align: center;
    flex: 1;
  }

  &__profile-info {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    height: 100%;

    &-wrapper {
      padding-top: rem(10px);
      display: flex;
      flex-direction: row;
      gap: rem(20px);
    }
  }

  &__full-name {
    font-family: "Lato";
    font-style: normal;
    font-size: rem(34px);
    font-weight: 800;
    color: $color-blue-dark-1;
  }

  &__details-icon {
    padding: 0 rem(3px);
  }

  &__passkey-details {
    display: flex;
    flex-direction: row;
    font-size: rem(24px);
    font-weight: 700;
    align-items: center;
    justify-content: space-evenly;
    width:rem(140px);
    color: $color-blue-dark-1;
  }

  &__name {
    color: $color-orange-1;
  }

  &__profile-details {
    font-size: rem(24px);
    font-weight: 400;
    color: $color-blue-dark-1;
    
  }

  &__profile-image {
    width: rem(370px);
    height: rem(370px);
    background-color: $color-blue-light-1;
    color: $color-white;
  } 
  
  &__info-value {
    font-size: rem(24px);
    font-weight: 700;
  }
  &__info-key {
    font-size: rem(24px);
    font-weight: 400;
    opacity: 0.6; 
  }

  &__communications {
    flex: 1;
    display: flex;
    flex-direction: column;
    @include respondOrientation(port) {
      margin: rem(10px);
    }

    &--top {
      margin-bottom: rem(10px);
      // flex: 1; 
    }
    &--bottom-active {
      flex: 1 1 32%;
    }
    &--bottom {
      flex: 0 0 32%;
    }
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    flex: 0 0 rem(82px);
  }

  &__title {
    @include primaryFont;
    font-size: rem(28px);
    font-weight: 700;
    color: $color-blue-dark-1;
  }

  &__buttons-content {
    border-radius: rem(5px);
    background-color: $color-white;
    height: 100%;
    padding: 0 rem(24px);
    display: flex;
    flex-direction: column;
  }

  &__play-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    // width: rem(150px);
    

    &-text {
      padding: rem(6px) rem(10px);
      font-size: rem(24px);
      font-weight: 700;
      line-height: rem(28.8px);
    }
  }

  &__qna-item {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: rem(24px);

    &-top {
      padding-top: 0;
    }
  }

  &__item {
    display: flex;
    width: 90%;
    align-items: center;
    padding: rem(24px) 0;
    margin: 0 auto;

    &-top {
      padding-top: 0;
    }
    // &:not(:last-child) {
    //     margin-bottom: rem(24px);
    // }
  }

  &__line {
    width: rem(28px);
    height: 2px;
    background-color: black;

    &--1 {
        background-color: $color-pink-1;
    }

    &--2 {
        background-color: $color-pink-1;
    }

    &--3 {
        background-color: $color-pink-1;
    }

    &--4 {
        background-color: $color-pink-1;
    }

    &--5 {
        background-color: $color-orange-2; 
    }

    &--6 {
        background-color: $color-purple-light-1; 
    }

    &--7 {
        background-color: $color-blue-light-1; 
    }

    &--8 {
      background-color: $color-orange-2; 
    }

    &--9 {
      background-color: $color-purple-light-1; 
    }

    &--10 {
      background-color: $color-blue-light-1; 
    }

    &--11 {
      background-color: $color-pink-1;
    }
  }

  &__information-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba($color-white, 0.8);
    box-shadow: 0px 0px 4px rgb(15 40 76 / 26%);
    padding: rem(10px) rem(12px);
    height: rem(90px);
    flex: 1;
    
    &-dialog {
        padding: rem(20px) rem(300px) rem(20px) rem(20px);

        &--next {
            border: 0.1rem solid #00b4cd80;
            border-radius: 2px;
            background-color: #00b4cd29;
        }
    }
  }

  &__responses {
    display: flex;
    flex-direction: column;
    margin-left: rem(9px);
    font-size: rem(16px);

    &-unread {
      background-color: $color-gray-light-6;
    }
    
    &-icons {
      position: relative;
      display: inline-block;
    }

    &-red-dot-icon {
      position: absolute;
      top: rem(-3px);
      right: rem(2px);
    }

    &-small-tablet {
      color: $color-blue-dark-1;
    }
  }

  &__text {
    @include primaryFont;
    font-weight: 400;
    font-size: rem(22px);
    color: $color-blue-dark-1;
    padding-inline-end: rem(24px) ;

    &__duration {
      color: rgba($color-blue-dark-1, 0.6);
    }
  }

  .p-avatar .p-avatar-icon {
    font-size: rem(110px);
  }

  .button-loading__near-bed-action .p-progress-spinner {
    position: unset;
  }

  .button-loading__near-bed-stop-communication .p-progress-spinner {
    position: unset;
  }
}

.p-progress-spinner {
  height: rem(14px);
  position: unset;
}
.recordings-list {
    display: flex;
    flex-direction: column;

    border-radius: rem(5px);
    background: $color-white;
    box-shadow: 0px 2px 15px 0px rgba(89, 123, 142, 0.15);
    padding: rem(18px);
    margin-bottom: rem(20px);

    &__main {
        display: flex;
        flex-direction: column;
        .p-accordion-header-text {
            width: 100%;
        }
        .p-accordion-tab {
            margin-bottom: rem(8px);
        }
    }

    &__icon-wrapper {
        margin: 0 rem(10px);

        &--inverted {
            transform: rotate(180deg);
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;

        &-title {
            color: $color-blue-dark-1;
            @include primaryFont;
            font-weight: 600;
            font-size: rem(18px);
            margin: rem(12px) 0 rem(26px) 0;
            padding: 0 rem(3px);
        }

        &-invite-button {
        }
    }

    &__sub-header {
        display: flex;
        justify-content: space-between;
        margin: 0 rem(2px);
        width: 100%;

        &-start {
            display: flex;
            align-items: center;

        }

        &-text {
            color: $color-blue-dark-1;
            @include primaryFont;
            font-weight: 400;
            font-size: rem(14px);
        }

        &-end {
        }
    }

    &__number-records {
        display: flex;
        margin: 0 rem(6px);
        color: $color-gray-light-3;
        @include primaryFont;
        font-weight: 400;
        font-size: rem(14px);

        &--text {
            margin: 0 rem(3px)
        }
    }

    &__list {
        list-style-type: none;
        display: flex;
        width: 100%;
        flex-direction: column;
    }


    @keyframes fadeInOut {
        0% {
            opacity: 0;
            transform: translateX(-3px);
        }

        100% {
            opacity: 1;
            transform: translateX(0);
            transform: scale(1)
        }
    }

    &__item {
        @include flexSpaceBetweenCenter;
        padding: 0 2px;

        &:not(:last-child) {
            margin-bottom: rem(21px);
        }
    }

    

    &__item.animation {
        animation: fadeInOut 0.3s ease-in-out;
        background-color: $color-gray-light-2;
        border-radius: 2px;
    }

    &__item-start {
        @include flexSpaceBetweenCenter;
    }

    &__item-end {
        @include flexSpaceBetweenCenter;
    }

    &__play {
        button {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__name {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(14px);
        color: $color-blue-dark-1;
        margin: 0 rem(8px);
        min-width: rem(120px);
    }

    &__name-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: rem(120px); 
    }

    &__duration {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(12px);
        color: $color-gray-light-3;
        padding: 0 rem(12px);
        margin-left: rem(15px);
        min-width: rem(59px);
    }


    &__date {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(12px);
        color: $color-gray-light-3;
        padding: 0 rem(20px);
        border-left: rem(1px) solid $color-gray-light-2;
    }

    &__active-toggle {

        .p-inputswitch .p-inputswitch-slider {
            background: $color-white;
            border: rem(1px) solid $color-blue-dark-1;
        }

        .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
            background: $color-white;
            border: rem(1px) solid $color-blue-dark-1;
        }

        .p-inputswitch .p-inputswitch-slider:before {
            background: #ced4da;
        }

        .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
            background: $color-blue-light-1;
            margin: rem(-6.25px) -1px;
        }

        .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
            background: $color-white;
            border: rem(1px) solid $color-blue-dark-1;
        }

        .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
            background: $color-white;
            border: rem(1px) solid $color-blue-dark-1;
        }
    }

    &__download {
        padding: 0 rem(13px);
        cursor: pointer;
    }

    &__delete {
    }

    &__accordion-tab {
         max-height: rem(400px);
         overflow-y: auto;
    }

    &__upload-button {
        width: rem(65px);
        height: rem(32px);
        font-size: rem(14px);
        margin: 0 rem(8px);
        
    }
}

.recording-general {
    &__play-button {
        background: $color-blue-light-1;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg.play-icon {
            height: rem(15px);
            position: relative;
         
        }

        svg.square-icon {
            position: relative;
            height: rem(12px);
        }
    }

    &__play-button.p-button.p-button-text:enabled:active,
    .p-button.p-button-text:not(button):not(a):not(.p-disabled):active {
        background: transparent;
        color: #6366F1;
        border-color: transparent;
    }

    &__play-button.p-button:focus {
        box-shadow: none;
    }

}
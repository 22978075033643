.notifications {
    @include patientViewInformation;


    @include respond(tab-land) {
            height: rem(400px);
            margin-bottom: rem(20px);
        }

    &__filter {
        font-size: rem(14px);
        color: $color-blue-dark-1;
        padding-bottom: rem(32px);
    }
    
    &__span-wrapper {
        padding-right: rem(20px);
        
        &__filter-option-selected {
            font-weight: 600;
            border-bottom: 1px solid;
            padding-bottom: rem(10px);
        }
    }

    &__items {
        list-style: none;
        width: 100%;
        height: 90%;
        overflow-y: auto;
        padding: rem(7px);
    }

    &__header {
        @include primaryFont;
        font-weight: 600;
        font-size: rem(18px);
        color: $color-blue-dark-1;
        padding-bottom: rem(12px);
    }

    &__item {
        display: flex;
        width: 100%;
        align-items: center;
        border-radius: rem(5px);
        
        &:not(:last-child) {
            margin-bottom: rem(16px);
        }
    }
    
    &__action-required {
        border: $color-action solid rem(1px)
    }

    &__icon {
        padding-right: rem(4px);
    }


    &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        box-shadow: 0px 0px 4px rgb(15 40 76 / 26%);
        padding: rem(10px) rem(12px);
    }

    &__information {
        display: flex;
    }

    &__trash-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 rem(16px);
    }

    &__date {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(12px);
        color: rgba($color-blue-dark-1, 0.6);
        text-align: right;
    }

    &__text {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(14px);
        color: $color-blue-dark-1;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
    }

    &__results { 
        position: relative;
    }
    
    &__results-download {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(14px);
        color: $color-blue-light-1;
        display: flex;
        align-items: center;
        position: absolute;
        top: rem(-14px);
        left: rem(7px);
        cursor: pointer;
    }
}
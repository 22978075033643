.settings-page {
  color: black;
  display: flex;
  flex-direction: column;
  flex: 1;

  &__title-wrapper {
    display: flex;
    align-items: center;
    padding: 0 rem(26px);
    flex: 0 0 rem(80px);
  }

  &__header {
    @include primaryFont;
    font-weight: 400;
    font-size: rem(24px);
    line-height: rem(32px);
  }

  &__body {
    display: flex;
    flex: 1;
  }

  &__side-bar {
    flex: 0 0 rem(171px);
    display: flex;
    padding: 0 rem(20px);
  }

  &__content {
    flex: 1;
    display: flex;
  }
}

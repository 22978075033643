.device-information-bar {
    display: flex;
    align-items: center;
    width: 100%;

    background: $color-white;
    box-shadow: 0px 2px 15px rgba(89, 123, 142, 0.15);
    border-radius: 5px;
    padding: rem(25px) rem(30px) rem(25px) rem(30px);

    &__serial-wrapper {
        border-right: 1px solid rgba($color-gray-light-2, 1);
        min-width: rem(170px);
        height: rem(54px);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__serial {
        display: flex;
        flex-direction: column;
        @include primaryFont;
        font-weight: 600;
        font-size: rem(24px);
        color: $color-orange-1;
        padding-right: rem(45px);
    }

    &__info-box {
        width: 100%;
        display: flex;
        align-items: center;
    }

    &__text-font {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(14px);
        color: $color-blue-dark-1;
        opacity: 0.6;
    }

    &__general-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0  0 0 rem(45px);
    }

    &__connection-status-wrapper {
        display: flex;
        flex-direction: column;
        height: rem(47px);
        align-items: center;
        justify-content: space-between;
    }

    &__connection-status {
        padding-top: 1px;
    }

    &__connection-status-text {}

    &__room {
        display: flex;
        flex-direction: column;
        padding: 0 rem(45px);
        height: rem(47px);
        align-items: center;
        justify-content: space-between;
    }

    &__room-number {
        @include primaryFont;
        font-weight: 600;
        font-size: rem(16px);
        color: $color-blue-dark-1;

    }

    &__room-text {}

    &__patient-wrapper {
        display: flex;
        flex-direction: column;
        height: rem(47px);
        justify-content: space-between;
        align-items: center;
    }

    &__patient-name {
        @include primaryFont;
        font-weight: 600;
        font-size: rem(16px);
        color: $color-blue-dark-1;
    }

    &__device-number-text {}

}
.toast-handler {
    position: absolute;
    .p-toast {
        opacity: 1;
        width: rem(335px);
        max-width: rem(335px);
        height: rem(75rem);
        z-index: -4;
    }

    .p-toast.p-component.p-toast-top-right {
        height: auto;
        top: rem(81px)
    }

    .p-toast.p-component {
        &>div {
            width: 100%;
            height: 100%;
        }

        .p-toast-message {
            background: $color-white;
            width: 100%;
            height: 100%;
            border-width: 0 0 0 rem(8px);

            .p-toast-message-content {
                height: 100%;

                .p-toast-message-text {
                    @include primaryFont;
                    color: $color-blue-dark-1;
                    margin: 0 0 0 1.6rem;

                    .p-toast-summary {
                        font-weight: 700;
                        font-size: rem(18px);
                    }

                    .p-toast-detail {
                        font-weight: 400;
                        font-size: rem(14px);
                    }
                }
            }

            .p-toast-message-icon {
                display: flex;
                width: rem(40px);
                height: 100%;
                align-items: center;
                justify-content: center;

                &::before {
                    content: url('../../svgs/alert.svg');
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .p-toast-message.p-toast-message-success {
            border-color: $color-blue-light-2;
            .p-toast-message-icon {
                &::before {
                    content: url('../../svgs/success.svg');
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .p-toast-message.p-toast-message-error {
            border-color: $color-alert;
            .p-toast-message-icon {
                &::before {
                    content: url('../../svgs/alert.svg');
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .p-toast-message.p-toast-message-info {
            border-color: $color-blue-dark-1;
            .p-toast-message-icon {
                &::before {
                    content: url('../../svgs/information.svg');
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        
    }

    .p-toast-icon-close {}

    .p-toast-icon-close-icon {
        color: $color-blue-dark-1;
        font-size: rem(15px);
    }
}
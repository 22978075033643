.daily-scheduled-content {
    display: flex;
    align-items: center;
    max-width: rem(580px);

    &__scroll-panel {
        width:100%;
        color: black;

        .p-scrollpanel{
            padding: .5rem;
            line-height: 1.5;
            margin: 0;

            &-bar {
                color: black
            }
        }
        
        .p-scrollpanel.custombar1 .p-scrollpanel-wrapper {
            border-right: 10px solid var(--surface-b);
        }
        
        .p-scrollpanel.custombar1 .p-scrollpanel-bar {
            background-color: var(--primary-color);
            opacity: 1;
            transition: background-color .2s;
        }
        
        .p-scrollpanel.custombar1 .p-scrollpanel-bar:hover {
            background-color: #007ad9;
        }
        
        .p-scrollpanel.custombar2 .p-scrollpanel-wrapper {
            border-right: 10px solid var(--surface-b);
            border-bottom: 10px solid var(--surface-b);
        }
        
        // .p-scrollpanel.custombar2 .p-scrollpanel-bar {
        //     background-color: var(--surface-d);
        //     border-radius: 0;
        //     opacity: 1;
        //     transition: background-color .2s;
        // }

        .p-scrollpanel-bar {
            background-color: #D6D9DD;
        }

        .p-scrollpanel-bar:hover {
            background-color: #D6D9DD;
        }
        
        // .col-12 {
        //     padding: 2rem;
        // }
    }

    &__content-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 rem(12px);
    }
    &__title {
        display: flex;
        align-items: center;
    }
    &__title-icon {

    }
    &__title-text {
        margin: 0 rem(10px);
        @include primaryFont;
        font-weight: 700;
        font-size: rem(28px);
        color: $color-blue-dark-1;
    }
    &__icon {

    }
    &__text {
        @include primaryFont;
        font-weight: 400;
        font-size: rem(24px);
        color: $color-blue-dark-1;
        margin: 0 rem(32px);
    }
    &__header.p-dialog-header.p-dialog-header {
        background-color: $color-gray-light-4;
        height: rem(82px);
    }
}
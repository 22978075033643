.custom-table {
    border: none;
    background: $color-gray-light-4;
    border-radius: 5px;
  
    .p-datatable .p-datatable-tbody>tr.overview__table__alert {
      background: rgba(180, 142, 126, 0.05);
    }
  
    &__icons-wrapper {
      position: relative;
      display: flex;
      align-items: center;
    }
  
    &__icons>* {
      padding-left: 0.5rem;
    }
  
    &__icons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      right: rem(25px);
      width: rem(160px);
    }
  
    .p-datatable-thead {
      @include primaryFont;
      border-radius: rem(5px);
      height: rem(46px);
    }

    .p-datatable-wrapper {
        border-radius: 5px;
    }
  
    .p-column-title {
      @include primaryFont;
      font-weight: 400;
      font-size: rem(12);
      color: $color-gray-dark-1;
    }
  
    .p-column-header-content {
      justify-content: start;
    }

    tr > th:first-child, tr > td:first-child {
        padding: 0px 0 0px rem(25px);
    }

  
    &__table-row {
      height: rem(66px);
      cursor: pointer;
    }
  
    &__data {
      @include primaryFont;
      font-weight: 400;
      font-size: rem(12px);
      color: $color-blue-dark-1;
      text-align: start;
      vertical-align: middle;
    }
  }
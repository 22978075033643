.scrolling-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 1;

  &__main {
    display: flex;
    justify-content: flex-start;
    flex: 1;
  }

  &__header {
    @include primaryFont;
    font-weight: 700;
    font-size: rem(18px);
    line-height: rem(22px);
    color: $color-blue-dark-1;
    margin-bottom: rem(18px);
    width: 82.5rem;
  }

  &__sub-title {
    @include primaryFont;
    font-weight: 400;
    font-size: rem(14px);
    line-height: rem(20px);
    margin-bottom: rem(45px);
  }

  &__side-bar {
    flex: 1 1 rem(175px);
  }

  &__side-bar-item-link {
    display: inline-block;
    @include primaryFont;
    font-weight: 600;
    font-size: rem(14px);
    line-height: rem(32px);
    cursor: pointer;
  }

  &__side-content {
    padding: 0 rem(20px);
    height: 100%;
    width: 100%;
    position: absolute;
    overflow-y: auto;
  }

  &__side-bar-items {
    list-style: none;
  }

  &__side-bar-item {
    margin-bottom: rem(20px);
    color: rgba($color-blue-dark-1, .6);
    .active {
      color: $color-blue-dark-1;
    }
  }

  &__side-content-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
  }
  
  &__blank {
    height: rem(500px);
  }
  
  &__device {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    margin-bottom: 10px;
  }
}

.navbar {
  flex: 0 1 rem(61px);
  display: flex;
  justify-content: space-between;
  background-color: $color-blue-dark-1;

  &__near-to-bed {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    &__end {
      padding-right: rem(5px);
    }

    &__back-text {
      margin: 0 rem(10) 0 rem(10);
      font-family: "Lato";
      font-weight: 700;
      font-size: rem(20px);
    }

    &__arrow-btn {
      cursor: pointer;
      width: rem(32px);
      font-size: rem(13px);
      border: none;
      background-color: $color-blue-dark-1;

      &--right {
          background: linear-gradient(275.72deg, $color-blue-dark-1 5.59%, rgba(255, 255, 255, 0) 450.07%);   
      }
    }

    &__more-btn {
      cursor: pointer;
      font-size: rem(13px);
      border: none;
      background-color: $color-blue-dark-1;

      &--right {
          background: linear-gradient(275.72deg, $color-blue-dark-1 5.59%, rgba(255, 255, 255, 0) 450.07%);   
      }
    }
  }

  &__icon-box {
    height: 100%;
    padding: rem(11px) rem(30px) rem(14px) rem(30px);
  }
  .p-tabmenu {
    height: 100%;

    .p-tabmenu-nav {
      height: 100%;
      background: none;
      border: none;

      .p-tabmenuitem .p-menuitem-link {
        background: none;
        border-color: $color-transparent;
        height: 100%;
        border-width: 0 0 3px 0;
      }

      .p-tabmenuitem.p-highlight .p-menuitem-link {
        border-color: $color-blue-light-1;
        height: 100%;
        span {
          color: $color-blue-light-1;
        }
      }
      .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
        background: none;
        border-color: $color-transparent;
      }

      .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
        outline: none;
        outline-offset: 0;
        box-shadow: none;
      }

      li {
        display: flex;
        justify-content: center;
        background: $color-transparent;
        height: 100%;
        cursor: pointer;

        .p-menuitem-link {
          background: none;
        }
      }
      a {
        height: 100%;
        span {
          @include primaryFont;
          color: $color-white;
          font-weight: 400;
          font-size: rem(16px);
          line-height: rem(32px);
        }
      }
    }
  }

  &__item-group {
    display: flex;
  }

  &__profile-box {
    display: flex;
    align-items: center;
    padding: rem(13px) rem(17px) rem(16px) rem(17px);
  }

  &__profile-name {
    @include primaryFont;
    color: $color-white;
    line-height: rem(32px);
    font-family: "Lato";
    font-weight: 700;
    font-size: rem(12px);
  }

  &__profile-image {
    margin: rem(0) rem(12px);
    width: rem(35px);
    height: rem(32px);
    background-color: $color-blue-light-1;
    color: $color-white;

    img {
      width: rem(35px);
      height: rem(32px);
      border: rem(1.5px) solid $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__profile-box-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .toast-refresh {
    border-left: 12px solid $color-blue-dark-2;
    border-radius: 5px;

    &__content-container {
      display: flex;
      align-items: center;
      flex: 1;
    }

    &__icon {
      margin-right: rem(10px);
    }

    &__title {
      font-size: rem(18px);
      font-weight: 700;
      color: $color-blue-dark-1;
    }

    &__text {
      font-size: rem(14px);
      color: $color-blue-dark-1;

      &__link {
        color: $color-blue-light-6;
        margin-right: 2px;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
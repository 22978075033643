.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include primaryFont;
    font-weight: 700;
    font-size: rem(14px);
    border: none;
    border-radius: rem(10px);
    padding: rem(20px) rem(30px);
    box-shadow: 0px rem(6px) rem(14px) rgba(4, 85, 97, 0.15);
    color: $color-white;
    cursor: pointer;

    &.p-button:focus {
        box-shadow: 0px rem(6px) rem(14px) rgba(4, 85, 97, 0.15);
    }

    &.p-button.p-button-outlined {
        background-color: $color-blue-light-1; 
        color: $color-white;
    }

    &.p-button:enabled:hover {
        box-shadow: 0px 6px 14px rgba(4, 83, 94, 0.32);
        background-color: $color-blue-light-1;
    }

    &-disabled.button.p-button:enabled:hover {
        cursor: not-allowed;
        background-color: rgba($color-blue-light-1, 0.4);
        box-shadow: unset;
    }

    &.p-button-outlined:enabled:hover {
        box-shadow: 0px 6px 14px rgba(4, 83, 94, 0.32);
        background-color: $color-white;
        color: $color-blue-light-1;
    }

    &.p-button-outlined-2 {
        background-color: $color-white;
        color: $color-blue-light-1;
    }
    
    &-regular {
        padding: rem(14px) rem(31px);
        background: $color-blue-light-1;
    }

    &-nurse {
        width: rem(200px);
    }

    &-near-bed {
        width: 100%;
        margin-top: rem(10px);
        padding: rem(14px) rem(31px);
        background: $color-blue-light-1;
    }

    &-camera {
        padding: rem(14px) rem(21px) rem(14px) rem(21px);

        &--disabled {
            opacity: 0.4;
            cursor: not-allowed;
        }
    }
    
    &-near-bed-left {
        border-radius: rem(10px);
        padding: rem(14px) rem(20px);
        margin-left: rem(10px);
    }

    &-x {
        background: $color-blue-dark-3;

        &.p-button:enabled:hover {
            box-shadow: 0px 6px 14px rgba(4, 83, 94, 0.32);
            background-color: $color-blue-dark-3;
        }
    }


    &-disabled.button {
        background-color: rgba($color-blue-light-1, 0.4);
        box-shadow: unset;
    }
    
    &-disabled-stop-tablet {
        cursor: not-allowed;
        opacity: 0.4;
    }

    &-form {
        width: rem(135px);
        height: rem(45px);
        background-color: $color-blue-light-1;
    }

    &-form-cancel {
        margin: 0 rem(10px);
    }
    &-loading {
        color: $color-blue-dark-1;

        svg {
            height: 65%;
        }
  
        @keyframes p-progress-spinner-color {
  
          100%,
          0% {
            stroke: $color-blue-dark-1;
          }
  
          40% {
            stroke: $color-blue-light-1;
          }
  
          66% {
            stroke: $color-blue-dark-1;
          }
  
          80%,
          90% {
            stroke: $color-blue-light-1;
          }
        }

        &__near-bed {
            &-action {
                width: rem(90px);
                height: rem(30px);
                padding: rem(20px) rem(41px);
            }

            &-stop-communication {
                width: rem(100px);
                height: rem(30px);
                padding: rem(20px) rem(41px);
            }
        }
    }

    &-cancel {
        color: $color-blue-light-1;
        background-color: $color-white;
        border: $color-blue-light-1;
        border: rem(1px) solid;
    }

    &-responses {
        color: $color-blue-light-1;
        background-color: $color-white;
        border: rem(1px) solid;

        &-small-tablet {
            border-radius: rem(10px);
            padding: rem(14px) rem(20px);
            margin-left: rem(24px);
        }
    }

    &-big {
        padding: rem(26px) rem(70px);
        font-size: rem(40px);
    }

    &-play {
        width: rem(20px);
        height: rem(20px);
        background-color: $color-blue-light-1;
        box-shadow: none;

        border-radius: 100px;
        display: flex;
        align-items:center;
        justify-content: center;
        svg {
            position: absolute;
        }

        &.p-button:enabled:hover {
            box-shadow: none;
        }
        &.p-button:not(button):not(a):not(.p-disabled):hover {
            box-shadow: none;
        }
    }

    &-dropdown {
        display: flex;
        color: $color-blue-light-1;
        width: 100%;
        border-radius: 1px;
        height: rem(35px);
        background-color: $color-white;
        border: 1px solid #00b4cd3d;
        box-shadow: none;
        font-weight: 500;

        &--disabled.p-disabled,.p-component:disabled {
            opacity: 0.4;
            cursor: not-allowed;
        }


        &.p-button.p-button-outlined {
            color: $color-blue-light-1; 
        }

        &.p-button:enabled:hover {
            box-shadow: none;
            background-color: $color-white;
            border: 1px solid #00b4cd3d;
        }

        &.p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover{
            box-shadow: none;
            background-color: $color-white;
            color: $color-blue-light-1;
            border: 1px solid #00b4cd3d;
            background-color: #00b4cd0a;
        }
    }
}
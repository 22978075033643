.broadcast-method {
    &__header-wrapper {
        @include headerWrapper;
    }

    &__reset-button {
        @include resetButton;
    }
    &__reset-button:hover {
        cursor: pointer;
    }

    &__radio-button {
        .p-radiobutton {
            vertical-align: baseline;
        }

        .p-radiobutton .p-radiobutton-box {
            border-color: $color-blue-light-1;
            background-color: $color-white;
            height: rem(20);
            width: rem(20);
        }

        .p-radiobutton-box .p-radiobutton-icon {
            background-color: $color-blue-light-1;
            height: rem(12);
            width: rem(12);
        }

        .p-radiobutton .p-radiobutton-box.p-highlight {
            border-color: $color-blue-light-1;
        }
    }

    &__description {
        @include cardContarinerDefault;
        @include text;
    }

    &__radio-button-label {
        @include input-label;
    }
}
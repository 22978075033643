.custom-accordion {
    width: 100%;
  }
  
  .custom-accordion__item {
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-bottom: rem(8px);
    overflow: hidden;
  }
  
  .custom-accordion__item-header {
    height: rem(62px);
    background: var(--gray-light, $color-gray-light-4);
    box-shadow: 0px 0px 4px 0px rgba(15, 40, 76, 0.16);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0 rem(12px);
  
    &:hover {
      background-color: $color-gray-light-2
    }
  }
  
  .custom-accordion__item-header--start {

  }
  
  .custom-accordion__item-header--end {
    display: flex;
    align-items: center;
  }
  
  .custom-accordion__item-icon {
  }
  
  .custom-accordion__content {
    display: flex;
    width: 100%;
    padding: rem(12.5px);
    max-height: 40rem;
    overflow-y: auto;
    flex-direction: column;
  }
.device-settings-header {
    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: rem(20px);
    }
    
    &__link {
        @include primaryFont;
        font-weight: 700;
        font-size: rem(16px);
        color: $color-blue-dark-1;
    }
    
    &__link-text {
        opacity: 0.6;
        cursor: pointer;
    }
    
    &__buttons {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    &__detach-button {
        color: $color-blue-light-1;
        background-color: $color-white;
        border: $color-blue-light-1;
        border: rem(1px) solid;
    }

    &__button {
        margin: rem(10px);
    }
}
.device-list {
    width: 100%;
    padding-right: rem(31px);
    border-radius: 5px;

    .p-datatable-emptymessage {
        td {
          @include primaryFont;
          font-weight: 400;
          font-size: rem(22px);
          color: $color-blue-dark-1;
          text-align: center;
        }
      }
}